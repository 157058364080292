<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none p-0" to="/dashboard">
      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      /> -->
      <router-link to="/dashboard" class="pl-0">
        <img
          class="c-sidebar-brand-full float-left"
          name="logo"
          size="custom-size"
          :height="45"
          viewBox="0 0 556 134"
          src="/cwasa-hd-logo.png"
          :alt="appTitle"
        />
      </router-link>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="menu" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  data() {
    return {
      appTitle: process.env.VUE_APP_NAME,
      menu: [],
      permissions:''
    }
  },
  nav,
  computed: {
    show() {
      return this.$store.state.SideBar.sidebarShow;
    },
    minimize() {
      return this.$store.state.SideBar.sidebarMinimize;
    },
  },
  methods: {
    checkPermissions(item) {
      if (!item.permissions) return true;
      for (let key in item.permissions) {
        let permStr = item.permissions[key];
        var newPermArr = permStr.split('_');
        if(!newPermArr[1]) {
            permStr += 'all'; // adding All with permission
        }

        if (
          this.permissions &&
          this.permissions.includes(permStr)
        ) {
          return true;
        }
      }

      return false;
    },
    menuMapping() {
      var menuItems = this.menuActiveClassAdd();
       menuItems[0]['_children'] = menuItems[0]['_children'].filter((child)=> {
         if(child.items) {
           child.items = child.items.filter((item)=> {
            return this.checkPermissions(item)
           })
         }
            return this.checkPermissions(child)
          })
      this.menu = menuItems;
    },
    menuActiveClassAdd(){
      var menuItems = this.$options.nav;
      menuItems[0]['_children'] = menuItems[0]['_children'].map((child)=>{
        child.color = ''
        if (this.$route.path === child.to){
          child.color = ' c-active'
        }
        return child;
      })
      return menuItems;
    }
  },
  mounted() {
    this.permissions = this.$auth.user().scopes;
    this.menuMapping();
  },
  watch:{
    $route (to, from){
      this.menuActiveClassAdd();
    }
  }
};
</script>

<style  scoped>
.c-sidebar ul {
  background-color: #2a3042;
}

.c-sidebar ul >>> a {
  background-color: #2a3042 !important;
}
.c-sidebar ul >>> a.c-active {
  background-color: #455174d4 !important;
}
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #455174d4 !important;
}
.c-sidebar a {
  background-color: #ffffff;
}

.c-sidebar button {
  background-color: #2a3042;
}
.c-sidebar-brand {
  justify-content: center !important;
  padding: 1rem 1rem;
}
</style>

<style>
.c-header .c-subheader {
  margin-top: -1px;
  border-top: none;
  border-bottom: none;
  background: #ebedef !important;
}
</style>
