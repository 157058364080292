export default [
    {
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavTitle',
                _children: ['Menu']
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Dashboard',
                to: '/dashboard',
                icon: 'cil-home',
                // badge: {
                //   color: 'primary',
                //   text: 'NEW'
                // }
            },
            //Information
            {
                _name: 'CSidebarNavDropdown',
                name: 'Information',
                permissions: ['ROCode_list', 'RSCode_list', 'Rate_list', 'Area_list', 'MiCode_list', 'Ward_list', 'Holiday_list'],
                icon: 'cil-list',
                items: [
                    {
                        name: 'Revenue Officer',
                        to: '/rocodes',
                        permissions: ['ROCode_list']
                    },
                    {
                        name: 'Revenue Supervisor',
                        to: '/rscodes',
                        permissions: ['RSCode_list']
                    },
                    {
                        name: 'Water Rate',
                        to: '/bills/rates',
                        permissions:['Rate_list'],
                    },
                    {
                        name: 'Areas',
                        to: '/areas',
                        permissions: ['Area_list']
                    },
                    {
                        name: 'Meter Inspector',
                        to: '/micodes',
                        permissions: ['MiCode_list']
                    },
                    {
                        name: 'Wards',
                        to: '/wards',
                        permissions: ['Ward_list']
                    },
                    {
                        name: 'Holidays',
                        to: '/holidays',
                        permissions: ['Holiday_list']
                    },
                ]
            },

            //Billing
            {
                _name: 'CSidebarNavDropdown',
                name: 'Billing',
                permissions: ['Reading_generate', 'Reading_list', 'Bill_generate', 'Bill_list'],
                icon: 'cil-list',
                items: [
                    {
                        name: 'Reading Generate',
                        to: '/reading/generate',
                        permissions: ['Reading_generate'],
                    },
                    {
                        name: 'Reading List',
                        to: '/reading/list',
                        permissions: ['Reading_list'],
                    },
                    {
                        name: 'Bill Generate',
                        to: '/bill/generate',
                        permissions: ['Bill_generate'],
                    },
                    {
                        name: 'Bill List',
                        to: '/bill/list',
                        permissions: ['Bill_list'],
                    },
                ]
            },
            /*{
                _name: 'CSidebarNavDropdown',
                name: 'Billing',
                permissions: ['Bill_', 'Bill_list'],
                icon: 'cil-list',
                items: [
                    {
                        name: 'BillGenerate',
                        to: '/bills/generate',
                        // permissions:['Bill_', 'Bill_list', 'BS_', 'BS_list'],
                    },
                    {
                        name: 'Bills',
                        to: '/bills',
                        permissions: ['Bill_', 'Bill_list', 'BS_', 'BS_list'],
                    },
                    // {
                    //   name: 'Settlements',
                    //   to: '/bills/settlements',
                    //   permissions:['BS_', 'BS_list'],
                    // },
                    {
                        name: 'Pricing',
                        to: '/bills/prices',
                        permissions: ['Pricing_', 'Pricing_list'],
                    },

                ]
            },
*/
            {
                _name: 'CSidebarNavItem',
                name: 'Payments',
                permissions: ['Payment_list'],
                to: '/payments',
                icon: 'cil-money'
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Consumer information',
                permissions: ['Consumer_list', 'Meter_list', 'History_all', 'WV_list', 'Task_list', 'CustomerSup_list', 'Feedback_list'],
                icon: 'cil-list',
                items: [
                    {
                        name: 'Consumer list',
                        to: '/consumers',
                        permissions: ['Consumer_list'],
                    },
                    {
                        name: 'Meters',
                        permissions: ['Meter_list'],
                        to: '/meters',
                    },
                    {
                        name: 'Meter Consumptions',
                        permissions: ['Meter_list'],
                        to: '/meter-consumptions',
                    },
                    {
                        name: 'Meter Error Logs',
                        permissions: ['Meter_list'],
                        to: '/error-logs',
                    },
                    {
                        name: 'History',
                        permissions: ['History_all'],
                        to: '/history',
                    },
                    {
                        name: 'Water Vehicle Requests',
                        permissions: ['WV_list'],
                        to: '/water-vehicle-requests',
                        // icon: 'cil-car-alt',
                    },
                    {
                        name: 'Tasks',
                        permissions: ['Task_list'],
                        to: '/tasks',
                        //icon: 'cil-task'
                    },
                    {
                        name: 'Consumer Support',
                        permissions: ['CustomerSup_list'],
                        to: '/customer-supports',
                        //icon: 'cil-headphones'
                    },
                    {
                        name: 'Consumer Feedback',
                        permissions: ['Feedback_list'],
                        to: '/customer-feedbacks',
                        //icon: 'cil-comment-square'
                    },
                ]
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Reports',
                permissions: ['Reports_billing', 'ReportsArr_billing', 'ReportsLed_billing', 'Reports_analytics'],
                icon: 'cil-chart-pie',
                items: [
                    {
                        name: 'Reports',
                        to: '/reports',
                        permissions: ['Reports_billing', 'ReportsArr_billing', 'ReportsLed_billing', 'Reports_analytics'],
                    },

                ]
            },
            {
                _name: 'CSidebarNavItem',
                name: 'Projects',
                to: '/projects',
                permissions: ['Project_list'],
                icon: 'cil-people'
            },

            /* {
               _name: 'CSidebarNavItem',
               name: 'Consumers',
               to: '/customer',
               icon: 'cil-user'
             }*/,
            {
                _name: 'CSidebarNavItem',
                name: 'Teams',
                to: '/teams',
                permissions: ['Team_list'],
                icon: 'cil-people'
            },
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Customer Applications',
            //   permissions:['CApp_', 'CApp_list'],
            //   to: '/customer-applications',
            //   icon: 'cil-user'
            // },
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Customer Accounts',
            //   permissions:['CAcc_', 'CAcc_list'],
            //   to: '/customer-accounts',
            //   icon: 'cil-user'
            // },

            {
                _name: 'CSidebarNavDropdown',
                name: 'Settings',
                permissions: ['System_Settings', 'Event_list', 'Notice_list', 'UserIndex_list', 'Team_list', 'Role_list'],
                icon: 'cil-settings',
                items: [
                    {
                        name: 'System Settings',
                        to: '/system-settings',
                        permissions: ['System_Settings']
                    },
                    {
                        name: 'Events',
                        to: '/events',
                        permissions: ['Event_list']
                    },
                    {
                        name: 'Notices',
                        to: '/notices',
                        permissions: ['Notice_list']
                    },
                    {
                        name: 'Users',
                        to: '/users',
                        permissions: ['UserIndex_list']
                    },
                    // {
                    //   name: 'Teams',
                    //   to: '/teams',
                    //   'Team_list']
                    // },
                    {
                        name: 'Roles',
                        to: '/roles',
                        permissions: ['Role_list'],
                    },

                ]
            },
            {
                _name: 'CSidebarNavDropdown',
                name: 'Inventory',
                permissions: ['Meter_list'],
                icon: 'cil-layers',
                items: [
                    {
                        name: 'Meters',
                        to: '/inventory/meters',
                        permissions: ['Meter_list']
                    },
                    {
                        name: 'Import Meters ',
                        to: '/inventory/import-meters',
                        permissions: ['Meter_list']
                    },

                ]
            },
        ]
    }
]
